<template>
    <v-container fluid>
        <v-row justify="center" align="center" v-if="!thank_you_state">
            <v-card width="90%" class="ma-15" elevation="0">
                <v-card-title style="word-break: break-word;">
                    <!-- <v-img :src="require('../../../../assets/email.png')" contain max-width="48" max-height="48"
                        class="mr-3" /> -->
                    <h5 v-if="!isMobile()" style="color: #4374F3 !important; font-size: 34px; font-weight: 600;">
                        Save your wishes</h5>
                    <small v-else style="font-size: 34px; font-weight: 600;">
                        Save your wishes
                    </small>
                </v-card-title>
                <v-card-text class="mt-10">
                    <v-row>
                        <v-col :cols="isMobile() ? '12' : '6'">
                            <v-text-field outlined v-model="c.firstname" dense label="First name"
                                style="font-family: 'Montserrat', sans-serif;">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile() ? '12' : '6'">
                            <v-text-field outlined v-model="c.lastname" dense label="Last name"
                                style="font-family: 'Montserrat', sans-serif;">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile() ? '12' : '6'">
                            <v-text-field outlined v-model="c.mobilenumber" dense type="number" label="Mobile Number"
                                style="font-family: 'Montserrat', sans-serif;">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile() ? '12' : '6'">
                            <v-text-field outlined v-model="c.email_address" dense label="Email"
                                style="font-family: 'Montserrat', sans-serif;">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile() ? '12' : '6'">
                            <v-text-field outlined v-model="c.address" dense label="Postal Address"
                                style="font-family: 'Montserrat', sans-serif;">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile() ? '12' : '6'">
                            <h3 v-if="isMobile()">What is your preferred method of communication?</h3>
                            <v-select :items="['Phone call', 'Email', 'Text message']"
                                :label="!isMobile() ? 'What is your preferred method of communication?' : 'Please select...'"
                                outlined v-model="c.reach" dense style="font-family: 'Montserrat', sans-serif;height:50px;">
                            </v-select>
                        </v-col>
                        <!-- <v-col
                            v-if="Object.keys($route.params).length === 0"
                        >
                            <strong>Choose a Funeral Director</strong>
                            <v-autocomplete
                                :items="get_fds"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                v-model="c.funeral_director"
                            >
                            </v-autocomplete>
                        </v-col> -->
                    </v-row>
                </v-card-text>
                <!-- <v-card-text
                    class="mt-8"
                >
                    <strong>I would like to...</strong>
                    <v-radio-group 
                        v-model="c.radioGroup"
                    >
                        <v-radio
                            v-for="(n, i) in radio_group_item"
                            :key="i"
                            :label="n.label"
                            :value="n.value"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text> -->
                <v-card-text class="text-center">
                    <v-btn color="primary" :disabled="saving ? true : false" @click="submit">
                        Save My Wishes
                    </v-btn>
                    <small style="display: block; font-family: Montserrat, sans-serif;" class="mt-5">By submitting this
                        form, you agree to our <a @click="terms_modal = true">Terms & Conditions</a> and <a
                            @click="privacy_modal = true">Privacy
                            Policy</a>.</small>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row v-else justify="center" align="center">
            <v-card width="90%" elevation="0">
                <v-card-title class="mt-15">
                    <v-img :src="require('../../../../assets/thankyou.svg')" contain width="250" height="250" />
                </v-card-title>
                <v-card-title class="justify-center">
                    <h2>Thank you for saving your wishes.</h2>
                </v-card-title>
                <v-card-subtitle class="text-center mt-5">
                    We will be in touch shortly.
                </v-card-subtitle>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="terms_modal" persistent max-width="600">
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="terms_modal = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="mt-5">
                        <h1>Terms and Conditions</h1>
                        <p>Last updated: May 19, 2023</p>
                        <p>Please read these terms and conditions carefully before using Our Service.</p>
                        <h1>Interpretation and Definitions</h1>
                        <h2>Interpretation</h2>
                        <p>The words of which the initial letter is capitalized have meanings defined under the following
                            conditions. The following definitions shall have the same meaning regardless of whether they
                            appear in singular or in plural.</p>
                        <h2>Definitions</h2>
                        <p>For the purposes of these Terms and Conditions:</p>
                        <ul>
                            <li>
                                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under
                                    common control with a party, where &quot;control&quot; means ownership of 50% or more of
                                    the shares, equity interest or other securities entitled to vote for election of
                                    directors or other managing authority.</p>
                            </li>
                            <li>
                                <p><strong>Country</strong> refers to: Ireland</p>
                            </li>
                            <li>
                                <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                                    &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to My Farewell Wishes, Hall
                                    Street, Kingscourt, Co. Cavan A82P4V8.</p>
                            </li>
                            <li>
                                <p><strong>Device</strong> means any device that can access the Service such as a computer,
                                    a cellphone or a digital tablet.</p>
                            </li>
                            <li>
                                <p><strong>Service</strong> refers to the Website.</p>
                            </li>
                            <li>
                                <p><strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these
                                    Terms and Conditions that form the entire agreement between You and the Company
                                    regarding the use of the Service. This Terms and Conditions agreement has been created
                                    with the help of the <a href="https://www.termsfeed.com/terms-conditions-generator/"
                                        target="_blank">TermsFeed Terms and Conditions Generator</a>.</p>
                            </li>
                            <li>
                                <p><strong>Third-party Social Media Service</strong> means any services or content
                                    (including data, information, products or services) provided by a third-party that may
                                    be displayed, included or made available by the Service.</p>
                            </li>
                            <li>
                                <p><strong>Website</strong> refers to My Farewell Wishes, accessible from <a
                                        href="app.myfarewellwishes.com" rel="external nofollow noopener"
                                        target="_blank">app.myfarewellwishes.com</a></p>
                            </li>
                            <li>
                                <p><strong>You</strong> means the individual accessing or using the Service, or the company,
                                    or other legal entity on behalf of which such individual is accessing or using the
                                    Service, as applicable.</p>
                            </li>
                        </ul>
                        <h1>Acknowledgment</h1>
                        <p>These are the Terms and Conditions governing the use of this Service and the agreement that
                            operates between You and the Company. These Terms and Conditions set out the rights and
                            obligations of all users regarding the use of the Service.</p>
                        <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with
                            these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others
                            who access or use the Service.</p>
                        <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You
                            disagree with any part of these Terms and Conditions then You may not access the Service.</p>
                        <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use
                            the Service.</p>
                        <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance
                            with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures
                            on the collection, use and disclosure of Your personal information when You use the Application
                            or the Website and tells You about Your privacy rights and how the law protects You. Please read
                            Our Privacy Policy carefully before using Our Service.</p>
                        <h1>Links to Other Websites</h1>
                        <p>Our Service may contain links to third-party web sites or services that are not owned or
                            controlled by the Company.</p>
                        <p>The Company has no control over, and assumes no responsibility for, the content, privacy
                            policies, or practices of any third party web sites or services. You further acknowledge and
                            agree that the Company shall not be responsible or liable, directly or indirectly, for any
                            damage or loss caused or alleged to be caused by or in connection with the use of or reliance on
                            any such content, goods or services available on or through any such web sites or services.</p>
                        <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party
                            web sites or services that You visit.</p>
                        <h1>Termination</h1>
                        <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any
                            reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
                        <p>Upon termination, Your right to use the Service will cease immediately.</p>
                        <h1>Limitation of Liability</h1>
                        <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of
                            its suppliers under any provision of this Terms and Your exclusive remedy for all of the
                            foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if
                            You haven't purchased anything through the Service.</p>
                        <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers
                            be liable for any special, incidental, indirect, or consequential damages whatsoever (including,
                            but not limited to, damages for loss of profits, loss of data or other information, for business
                            interruption, for personal injury, loss of privacy arising out of or in any way related to the
                            use of or inability to use the Service, third-party software and/or third-party hardware used
                            with the Service, or otherwise in connection with any provision of this Terms), even if the
                            Company or any supplier has been advised of the possibility of such damages and even if the
                            remedy fails of its essential purpose.</p>
                        <p>Some states do not allow the exclusion of implied warranties or limitation of liability for
                            incidental or consequential damages, which means that some of the above limitations may not
                            apply. In these states, each party's liability will be limited to the greatest extent permitted
                            by law.</p>
                        <h1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
                        <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults
                            and defects without warranty of any kind. To the maximum extent permitted under applicable law,
                            the Company, on its own behalf and on behalf of its Affiliates and its and their respective
                            licensors and service providers, expressly disclaims all warranties, whether express, implied,
                            statutory or otherwise, with respect to the Service, including all implied warranties of
                            merchantability, fitness for a particular purpose, title and non-infringement, and warranties
                            that may arise out of course of dealing, course of performance, usage or trade practice. Without
                            limitation to the foregoing, the Company provides no warranty or undertaking, and makes no
                            representation of any kind that the Service will meet Your requirements, achieve any intended
                            results, be compatible or work with any other software, applications, systems or services,
                            operate without interruption, meet any performance or reliability standards or be error free or
                            that any errors or defects can or will be corrected.</p>
                        <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any
                            representation or warranty of any kind, express or implied: (i) as to the operation or
                            availability of the Service, or the information, content, and materials or products included
                            thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy,
                            reliability, or currency of any information or content provided through the Service; or (iv)
                            that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are
                            free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
                        </p>
                        <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
                            applicable statutory rights of a consumer, so some or all of the above exclusions and
                            limitations may not apply to You. But in such a case the exclusions and limitations set forth in
                            this section shall be applied to the greatest extent enforceable under applicable law.</p>
                        <h1>Governing Law</h1>
                        <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your
                            use of the Service. Your use of the Application may also be subject to other local, state,
                            national, or international laws.</p>
                        <h1>Disputes Resolution</h1>
                        <p>If You have any concern or dispute about the Service, You agree to first try to resolve the
                            dispute informally by contacting the Company.</p>
                        <h1>For European Union (EU) Users</h1>
                        <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law
                            of the country in which you are resident in.</p>
                        <h1>United States Legal Compliance</h1>
                        <p>You represent and warrant that (i) You are not located in a country that is subject to the United
                            States government embargo, or that has been designated by the United States government as a
                            &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States
                            government list of prohibited or restricted parties.</p>
                        <h1>Severability and Waiver</h1>
                        <h2>Severability</h2>
                        <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be
                            changed and interpreted to accomplish the objectives of such provision to the greatest extent
                            possible under applicable law and the remaining provisions will continue in full force and
                            effect.</p>
                        <h2>Waiver</h2>
                        <p>Except as provided herein, the failure to exercise a right or to require performance of an
                            obligation under these Terms shall not effect a party's ability to exercise such right or
                            require such performance at any time thereafter nor shall the waiver of a breach constitute a
                            waiver of any subsequent breach.</p>
                        <h1>Translation Interpretation</h1>
                        <p>These Terms and Conditions may have been translated if We have made them available to You on our
                            Service.
                            You agree that the original English text shall prevail in the case of a dispute.</p>
                        <h1>Changes to These Terms and Conditions</h1>
                        <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a
                            revision is material We will make reasonable efforts to provide at least 30 days' notice prior
                            to any new terms taking effect. What constitutes a material change will be determined at Our
                            sole discretion.</p>
                        <p>By continuing to access or use Our Service after those revisions become effective, You agree to
                            be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please
                            stop using the website and the Service.</p>
                        <h1>Contact Us</h1>
                        <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                        <ul>
                            <li>
                                <p>By email: info@myfarewellwishes.ie</p>
                            </li>
                            <li>
                                <p>By phone number: 0426039990</p>
                            </li>
                        </ul>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="terms_modal = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="privacy_modal" persistent max-width="600">
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="privacy_modal = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="mt-5">
                        <h1>Privacy Policy of My Farewell Wishes</h1>
                        <p>This page is used to inform website visitors regarding our policies with the collection, use, and
                            disclosure of Personal Information if anyone decided to use our Service, the Website Name
                            website.</p>

                        <p>If you choose to use our Service, then you agree to the collection and use of information in
                            relation with this policy. The Personal Information that we collect are used for providing and
                            improving the Service. We will not use or share your information with anyone except as described
                            in
                            this Privacy Policy.</p>

                        <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                            which
                            is accessible at Website URL, unless otherwise defined in this Privacy Policy.</p>

                        <h1>Information Collection and Use</h1>
                        <p>For a better experience while using our Service, we may require you to provide us with certain
                            personally identifiable information, including but not limited to your name, phone number, and
                            postal address. The information that we collect will be used to contact or identify you.</p>

                        <h1>Log Data</h1>
                        <p>We want to inform you that whenever you visit our Service, we collect information that your
                            browser
                            sends to us that is called Log Data. This Log Data may include information such as your
                            computer's
                            Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time
                            and
                            date of your visit, the time spent on those pages, and other statistics.</p>

                        <h1>Cookies</h1>
                        <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier.
                            These are sent to your browser from the website that you visit and are stored on your computer's
                            hard drive.</p>

                        <p>Our website uses these “cookies” to collection information and to improve our Service. You have
                            the option to either accept or refuse these cookies, and know when a cookie is being sent to
                            your computer. If you choose to refuse our cookies, you may not be able to use some portions of
                            our Service.</p>

                        <h1>Service Providers</h1>
                        <p>We may employ third-party companies and individuals due to the following reasons:</p>

                        <p>To facilitate our Service;</p>
                        <p>To provide the Service on our behalf;</p>
                        <p>To perform Service-related services; or</p>
                        <p>To assist us in analyzing how our Service is used.</p>
                        <p>We want to inform our Service users that these third parties have access to your Personal
                            Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                            are obligated not to disclose or use the information for any other purpose.</p>
                        <h1>Security</h1>
                        <p>We value your trust in providing us your Personal Information, thus we are striving to use
                            commercially acceptable means of protecting it. But remember that no method of transmission over
                            the internet, or method of electronic storage is 100% secure and reliable, and we cannot
                            guarantee its absolute security.</p>
                        <h1>Links to Other Sites</h1>
                        <p>Our Service may contain links to other sites. If you click on a third-party link, you will be
                            directed to that site. Note that these external sites are not operated by us. Therefore, we
                            strongly advise you to review the Privacy Policy of these websites. We have no control over, and
                            assume no responsibility for the content, privacy policies, or practices of any third-party
                            sites or services.</p>
                        <h1>Children's Privacy</h1>
                        <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal
                            identifiable information from children under 13. In the case we discover that a child under 13
                            has provided us with personal information, we immediately delete this from our servers. If you
                            are a parent or guardian and you are aware that your child has provided us with personal
                            information, please contact us so that we will be able to do necessary actions.</p>
                        <h1>Changes to This Privacy Policy</h1>
                        <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page
                            periodically for any changes. We will notify you of any changes by posting the new Privacy
                            Policy on
                            this page. These changes are effective immediately, after they are posted on this page.</p>
                        <h1>Contact Us</h1>
                        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="privacy_modal = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        c: {
            firstname: null,
            lastname: null,
            mobilenumber: null,
            email_address: null,
            address: null,
            reach: null,
            radioGroup: null,
            funeral_director: null
        },
        radio_group_item: [
            {
                label: 'Receive a call back',
                value: 'Receive a call back'
            },
            {
                label: 'Create Online Planner',
                value: 'Create Online Planner'
            }
        ],
        thank_you_state: false,
        terms_modal: false,
        privacy_modal: false,
        saving: false
    }),
    async mounted() {
        await this.$store.dispatch('survey/get_fds')
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_funeral_service: 'survey/get_funeral_service',
            get_wake: 'survey/get_wake',
            get_ceremony: 'survey/get_ceremony',
            get_budget: 'survey/get_budget',
            get_funeral_cost: 'survey/get_funeral_cost',
            get_payment_preference: 'survey/get_payment_preference',
            get_personal_wishes: 'survey/get_personal_wishes',
            get_survey: 'survey/get_survey',
            get_fds: 'survey/get_fds'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async submit() {
            this.saving = true
            // if(this.c.firstname === null || this.c.lastname === null || this.c.mobilenumber === null || this.c.email_address === null || this.c.reach === null || this.c.radioGroup === null){
            if (this.c.firstname === null || this.c.lastname === null || this.c.mobilenumber === null || this.c.email_address === null || this.c.reach === null) {
                this.showSnackBar('Please fill out all fields')
                this.saving = false
                return
            }
            this.showSnackBar('Please wait...')
            let tp = {
                funeral_service: this.get_funeral_service,
                wake: this.get_wake,
                ceremony: this.get_ceremony,
                budget: this.get_budget,
                funeral_cost: this.get_funeral_cost,
                payment_preference: this.get_payment_preference,
                personal_wishes: this.get_personal_wishes,
                contact: {
                    firstname: this.c.firstname,
                    lastname: this.c.lastname,
                    mobilenumber: this.c.mobilenumber,
                    email_address: this.c.email_address,
                    reach: this.c.reach,
                    radioGroup: this.c.radioGroup
                },
                fd_id: Object.keys(this.get_survey).length > 0 ? this.get_survey.fd_id : 2,
                survey_id: Object.keys(this.get_survey).length > 0 ? this.get_survey.id : 0
            }
            await this.$axios.post('/non_auth/save_survey', tp)
                .then(({ data }) => {
                    this.saving = false
                    if (data.response) {
                        this.$store.commit('survey/set_clear_answer_r_state')
                        this.thank_you_state = true
                        window.fbq('track', 'Lead')
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>
  
<style scoped lang="scss"></style>